import React from 'react'

import * as styles from './Select.module.scss'

interface Props {
  options: string[];
  value: string;
  onChange: (value: string) => void;
  canBeEmpty?: boolean;
}

const Select = ({
  options,
  value,
  onChange,
  canBeEmpty = false
}: Props) => {
  return (
    <select
      className={styles.select}
      value={value}
      onChange={e => onChange && onChange(e.target.value)}
    >
      {canBeEmpty && <option disabled={!!value} value={undefined}></option>}
      {options && options.map(option => (
        <option value={option} key={option}>{option}</option>
      ))}
    </select>
  )
}

export default Select
