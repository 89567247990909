import React from 'react'
import Layout from "../components/Layout"
import { graphql } from "gatsby"
import { PageProps } from 'gatsby'
import { IGatsbyImageData } from "gatsby-plugin-image"
import * as styles from './Kontakt.module.scss'
import NetlifyForm from '../components/NetlifyForm'
import PinIcon from '../assets/pin.svg'
// import PhoneIcon from '../assets/phone.svg'
// import MobileIcon from '../assets/mobile.svg'
import EmailIcon from '../assets/mail.svg'
import { useIntl } from "gatsby-plugin-intl"

type KontaktQueryProps = PageQuery<{
  hero: HeroImageData<IGatsbyImageData>;
  title: string;
  body: MarkdownField;
  address: string;
  phone: string;
  mobile: string;
  email: string;
}>

const KontaktPage = ({ data }: PageProps<KontaktQueryProps>) => {
  const { locale, formatMessage } = useIntl()
  const { markdownRemark } = data
  const frontmatter = markdownRemark.frontmatter[locale]!

  const onSubmitContactForm = () => {
    alert('Vielen Dank für Ihre Anfrage')
  }

  return (
    <Layout
      seo={{title: frontmatter.title, description: frontmatter.body.html}}
      hero={frontmatter.hero}
      introCard={{
        title: frontmatter.title,
        body: frontmatter.body.html,
      }}
      wide
    >
      <div className={styles.contactCard}>
        <div className={styles.title}>{formatMessage({id: 'form.contactForm'})}</div>
        <NetlifyForm
          name={'kontakt-' + locale}
          onSubmit={onSubmitContactForm}
          className={styles.contactForm}
          submitButtonProps={{
            children: formatMessage({id: 'form.send'}),
            icon: 'send',
            align: 'flex-end',
            noMarginBottom: true,
            type: 'submit',
          }}
          rows={[
            {
              label: formatMessage({id: 'form.name'}),
              type: 'string',
              required: true,
            }, {
              label: formatMessage({id: 'form.email'}),
              type: 'string',
              required: true,
            }, {
              label: formatMessage({id: 'form.phone'}),
              type: 'string',
            }
          ]}
          secondColumnRows={[{
            label: formatMessage({id: 'form.message'}),
            type: 'text',
            fullHeightTextarea: true,
            required: true,
          }]}
        />
        <div className={styles.title}>{formatMessage({id: 'form.contactInformation'})}</div>
        <table className={styles.contactInfo}>
          <tbody>
            <tr>
              <td><PinIcon /></td>
              <td><a
                target='_blank'
                href={'https://www.google.com/maps/search/' + encodeURIComponent(frontmatter.address)}
                dangerouslySetInnerHTML={{__html: frontmatter.address.replace(/(?:\r\n|\r|\n)/g, '<br/>')}}
              /></td>
            </tr>
            {/* <tr>
              <td><PhoneIcon /></td>
              <td><a href={'tel:'+frontmatter.phone}>{frontmatter.phone}</a></td>
            </tr>
            <tr>
              <td><MobileIcon /></td>
              <td><a href={'tel:'+frontmatter.mobile}>{frontmatter.mobile}</a></td>
            </tr> */}
            <tr>
              <td><EmailIcon /></td>
              <td><a href={'mailto:'+frontmatter.email}>{frontmatter.email}</a></td>
            </tr>
          </tbody>
        </table>
        <iframe
          className={styles.gmaps}
          src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2530.2263502673377!2d7.334154876944664!3d50.64148727162941!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47beee769805049d%3A0x8e178f2671acbcec!2sK%C3%B6hlershohner%20Str.%2029%2C%2053578%20Windhagen!5e0!3m2!1sde!2sde!4v1689373917351!5m2!1sde!2sde'
          width='600'
          height='450'
          style={{border: 0}}
          allowFullScreen
          loading='lazy'
        />
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  fragment KontaktFragment on FrontmatterLocalized {
    hero {
      image {
        desktopHero: childImageSharp {
          gatsbyImageData(
            width: 1920
            aspectRatio: 2.5
            placeholder: BLURRED
            transformOptions: {cropFocus: CENTER}
          )
        }
        mobileHero: childImageSharp {
          gatsbyImageData(
            width: 760
            aspectRatio: 1.5
            placeholder: BLURRED
            transformOptions: {cropFocus: CENTER}
          )
        }
      }
    }
    title
    body {
      html
    }
    address
    phone
    mobile
    email
  }

  query KontaktPage {
    markdownRemark(fileAbsolutePath: {regex: "/pages\/kontakt.md/"}) {
      frontmatter {
        de {
          ...KontaktFragment
        }
        en {
          ...KontaktFragment
        }
      }
    }
  }
`

export default KontaktPage
