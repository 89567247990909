import React, { useState } from 'react'
import TextField from '../components/TextField'
import Button, { ButtonProps } from './Button'
import Select from '../components/Select'
import Checkbox from '../components/Checkbox'
import { useIntl } from "gatsby-plugin-intl"
import * as styles from './NetlifyForm.module.scss'

interface FormField {
  label: string;
  type: 'string' | 'text' | 'select';
  required?: boolean;
  options?: string[];
  fullHeightTextarea?: boolean;
}

interface NetlifyFormProps {
  children?: React.ReactNode;
  name: string;
  onSubmit?: () => void;
  className?: string;
  submitButtonProps: ButtonProps;
  hiddenFields?: {
    label: string;
    value: string;
  }[];
  rows: (FormField | FormField[])[];
  secondColumnRows?: (FormField | FormField[])[];
}

const NetlifyForm = ({ children, name, onSubmit, className, submitButtonProps, hiddenFields, rows, secondColumnRows }: NetlifyFormProps) => {
  const { formatMessage } = useIntl()
  const [fieldValues, setFieldValues] = useState<{[key: string]: string}>({})
  const [privacyAccepted, setPrivacyAccepted] = useState(false)
  const [privacyError, setPrivacyError] = useState(false)
  const [missingFields, setMissingFields] = useState<string[]>([])

  const updateFieldValue = (field: string, value: string) => {
    const missingIndex = missingFields.indexOf(field)
    if (missingIndex > -1) {
      setMissingFields(f => {
        const newFields = [...f]
        newFields.splice(missingIndex, 1)
        return newFields
      })
    }
    setFieldValues(oldValues => ({
      ...oldValues,
      [field]: value,
    }))
  }

  const handlePrivacyAccept = (accepted: boolean) => {
    setPrivacyAccepted(accepted)
    setPrivacyError(!accepted)
  }

  const renderField = (field: FormField) => {
    const fieldProps = {
      onChange: (newValue: string) => updateFieldValue(field.label, newValue),
      value: fieldValues[field.label] || '',
      hasError: missingFields.includes(field.label),
      key: field.label,
    }

    switch (field.type) {
      case 'string':
        return <TextField
          label={field.label + (field.required ? '*' : '')}
          {...fieldProps}
        />

      case 'select':
        const selectOptions = field.required ? field.options?.map(o => o + '*') : field.options
        return <Select
          options={selectOptions || []}
          {...fieldProps}
        />

      case 'text':
        return <TextField
          className={field.fullHeightTextarea ? styles.fullHeightTextarea : undefined}
          label={field.label + (field.required ? '*' : '')}
          multiline
          {...fieldProps}
        />

      default:
        return null
    }
  }

  const onFormSubmit: React.DOMAttributes<HTMLFormElement>['onSubmit'] = e => {
    e.preventDefault()
    const fieldsFlattened = [...rows, ...(secondColumnRows || [])].reduce((result: FormField[], current) => result.concat(current), [])

    const missingFieldNames = fieldsFlattened.filter(field => {
      if (field.required) {
        return !fieldValues[field.label]
      }
      return false
    }).map(f => f.label)
    setMissingFields(missingFieldNames)

    setPrivacyError(!privacyAccepted)

    if (!missingFieldNames.length && privacyAccepted) {
      const formData = new FormData(e.currentTarget)
      fetch(window.location.toString(), {
        method: 'POST',
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: new URLSearchParams(formData as any).toString(),
      })
        .then(() => onSubmit && onSubmit())
        .catch(error => alert(error))
    }
  }

  return (
    <form className={className} method='post' netlify-honeypot='bot-field' data-netlify='true' name={name} onSubmit={onFormSubmit}>
      {hiddenFields?.map(field => {
        return <input key={field.label} type='hidden' name={field.label} value={field.value} />
      })}
      <input type='hidden' name='form-name' value={name} />
      <input name='bot-field' style={{display: 'none'}} />
      <div className={styles.columnWrapper}>
        <div className={styles.column}>
          {rows?.map((row, rowIndex) => {
            return Array.isArray(row) ? <div key={rowIndex} className={styles.formRow}>{row.map(renderField)}</div> : renderField(row)
          })}
        </div>
        {secondColumnRows && <div className={styles.column}>
          {secondColumnRows.map((row, rowIndex) => {
            return Array.isArray(row) ? <div key={rowIndex} className={styles.formRow}>{row.map(renderField)}</div> : renderField(row)
          })}
        </div>}
      </div>
      <Checkbox
        className={styles.checkbox}
        value={privacyAccepted}
        onChange={handlePrivacyAccept}
      >
        <span dangerouslySetInnerHTML={{__html: formatMessage({id: 'form.privacyConfirm'})}} />*
      </Checkbox>
      <div className={styles.requiredFieldsNotice}>*{formatMessage({id: 'form.requiredFields'})}</div>
      {children}
      {!!missingFields.length && <p className={styles.errorMsg}>{formatMessage({id: 'form.fillAllFieldsMessage'})}</p>}
      {privacyError && <p className={styles.errorMsg}>{formatMessage({id: 'form.privacyErrormessage'})}</p>}
      <Button {...submitButtonProps} />
    </form>
  )
}

export default NetlifyForm
